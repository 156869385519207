
<template>
   <div v-editable="editing && blok" ref="animation_target" class="sb-textfield richtext" :id="[layout_classes.includes('references') && 'references' || blok.id != '' && blok.id || '']" v-html="richText" :class="layout_classes" > </div>
</template>

<script setup>
const animation_target = ref(null)
const props = defineProps({ blok: Object })
  const localePath = useLocalePath()
  const {handleInternalLink} = useConfig()
const { getLayoutClasses } = useLayoutControl()
  const {editing} = await useSettings()
const layout_classes = getLayoutClasses(props.blok)
const {icons,uiconfig,handleAnimation} = useUIControl()
onMounted(() => {
  // console.log(layout.a_enabled)
  if(props.blok.layout?.a_enabled)handleAnimation(animation_target,{layout:props.blok.layout,type:'textfield',a:{...props.blok.layout.animation}});
})

//     console.log(props.blok.content.content)
// let links = props.blok.content.content.forEach(c => {
//    c.marks?.forEach(m => {
//       console.log(m)
//       // m.attrs.linktype == "story"
//    })
//    // console.log("is internal link",is_link)
// })
const richText = computed(() => {
   // console.log(props.blok.content)
   let ret = renderRichText(props.blok.content, {
      resolver: (component, blok) => {
         // console.log(component,blok)
         switch (component) {
            case "TextFieldAnchor":
            return `<a name="${blok.id}"></a>`;
            break;
            default:
            return `Component ${component} not found`;
         }
      },
   })
   if (props.blok.has_references) {
        ret = ret.replace(
         /\[(.*?)]/g,
         "<a class='richtext__reference' href='#references'>$&</a>"
        );
        // "<a class='richtext__reference' href='#ref$1'>$&</a>"
      }
      //  ret = ret.replace('href="/pages/','href="/')
      // let m = ret.match(
      //  /\href="\/pages(.*?)"/g
      // );
      // console.log(localePath)
      // console.log(ret)
      const str = ret
      const regex = /<a(?=[^>]*\blinktype="story")[^>]*href="([^"]*)"[^>]*>(.*?)<\/a>/gm;
   const modifiedStr = str.replace(regex, (match, group1, group2) => {
      // console.log(match, group1, group2)
      //    console.log(group1)
      const newUrl = handleInternalLink(localePath(group1),true);
      // console.log(newUrl)// Replace with your function to modify the URL
      return match.replace(group1,newUrl)
      // return `<a href="${newUrl}" linktype="story">${group2}</a>`;
      });
      ret = modifiedStr
      // const regex = /<a.*?linktype="story".*?<\/a>/gm;
      // const replacement = '$&'.replace(/href="[^"]*"/gm, 'href="https://www.example.com"');
      // const result = ret.replace(regex, replacement);
      //  console.log(result)
      // ret = ret.replace(
      //  /\href="\/pages(.*?)"/g,
      //  `"href="$1"`
      // );
   if (layout_classes.includes('references')) {
      
      // let m = ret.match(/<li>(.*?)<\/li>/gm)
      // console.log(m)
   }
   // let m = ret.match(/\[(.*?)]/g)
   // console.log(m)
    return ret;
  
    
});
</script>
<style lang="scss">
   .sb-textfield{
      &.border-t-100{
         
         &::before{
            
            content:"";
            // position: absolute;
            display: block;

            height:2px;
            background-color: currentColor;
            width:100%;
            margin-bottom: 2rem;
            @include breakpoint(lg){
               margin-bottom:3rem ;
            }
         }
      }
      // *:last-child:not(:first-child){
         &+.sb-pagelink{
            margin-top:3rem;
            
         }
      &:not(.ignore-richtext){

         a[href]:not(.richtext__reference), a[href]:not(.richtext__reference):visited{
            color:$c-purple-100;
         }
         a[href]{
          text-decoration: underline;
         }
      }
      *:last-child:not(:first-child){
         margin-bottom:0px;
      }
      *:first-child{
         margin-top:0px;
      }
      &:not(:last-child){
         //  margin-bottom: 3rem;
         // @include breakpoint(lg){

         //    margin-bottom: 5rem;
         // }
      }
   
      &.text-center{
         text-align: center;
         p{
            margin-left:auto;
            margin-right:auto
         }
      }
      &.references{
         a{
            color:$c-purple-100;
         }
      }
      ol{
        list-style-type: decimal;
        padding-left:2rem;
        li{
         margin-bottom: .25rem;
         >*:last-child{
            margin-bottom:0px ;
         }
        }
      }
      ul{
        list-style-type:disc;
        padding-left:2rem;
        li{
         margin-bottom: .25rem;
         >*:last-child{
            margin-bottom:0px ;
         }
        }
      }
      p{
         max-width: 600px;
      }
      &.product-header{
         display: flex;
         align-items: baseline;
         flex-wrap: wrap;
         > *{
            margin-bottom:0px;
         }

      }
   }
   
</style>